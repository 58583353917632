import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  output = '';
  canMint = false;
  waitingFor = new Date('2023-03-25 17:00:00');
  constructor(private router: Router) {
    this.countDown();
  }

  goTo(route) {
    // console.log('go', route);
    this.router.navigateByUrl(route);
  }

  countDown() {
    // const countDownDate: any = new Date('2023-03-25 17:00:00').getTime();
    const countDownDate: any = Date.UTC(2023, 2, 23, 17);
    // const countDownDate: any = Date.UTC(2023, 2, 23, 6);

    // Update the count down every 1 second
    const x = setInterval(() => {

      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours: any = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds: any = Math.floor((distance % (1000 * 60)) / 1000);

      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      // Display the result in the element with id="demo"
      let output = '';
      if (days > 0) {
        output += days + ' Dayz ';
      }

      output += hours + ':' + minutes + ':' + seconds;

      this.output = output;

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        this.canMint = true;
        // document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
  }

  connect() {
    // console.log('Connecting');
  }

  gotoMint() {
    this.router.navigateByUrl('mint');
  }
}
