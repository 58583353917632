import { Injectable } from '@angular/core';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { WackyWassiesNFT, Whitelist } from './nft';
import Swal from 'sweetalert2';
// import BigNumber from "bignumber.js";
// BigNumber.config({
//     ROUNDING_MODE: 3,
//     DECIMAL_PLACES: 19,
//     EXPONENTIAL_AT: [-19, 19],
// });
@Injectable()
export class BlockchainService {


    public address = null;
    public signer;
    public publicPrice: any = 0;
    public prePrice: any = 0;
    public price: any = 0;
    public isPreSale = false;
    public isClaimable = false;
    public myWassies: any = 0;
    public isPublicSale = false;
    public isOpen = false;
    public provider;
    public ethereum;
    public chainId;
    public whitelisted = false;
    public whitelistMembers: any = 0;
    loading = false;
    constructor() {
        const { ethereum } = (window as any);
        this.ethereum = ethereum;
        this.checkWalletConnected();
    }
    public connectWallet = async () => {
        // console.log('connect');
        try {
            if (!this.ethereum) { return alert('Please install meta mask'); }
            const accounts = await this.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
            const { chainId } = await provider.getNetwork();
            console.log(chainId);
            if (chainId !== 1 && chainId !== 43113) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Please connect to Ethereum Mainnet',
                    heightAuto: false,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true
                });
            }
            else if (accounts[0] && (chainId === 1 || chainId === 43113)) {
                this.chainId = chainId;
                this.address = accounts[0];
                await this.checkWhiteList();
                await this.getPrice();
            }
        }
        catch (e) {
            throw new Error('No thereum object found');
        }
    };

    async checkWhiteList() {
        try {
            const contractAddress = this.chainId === 43113 ? Whitelist.testNetAddress : Whitelist.address;
            // console.log(contractAddress);
            const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
            const signer = provider.getSigner();
            const cont = new ethers.Contract(contractAddress, Whitelist.abi, signer);
            const amI = await cont.isAddressWhitelisted(this.address).then((re) => re);
            const whitelists = await cont.whiteListCount();
            this.whitelisted = amI;
            this.whitelistMembers = (+ethers.utils.formatEther(whitelists)) * 1000000000000000000;
        } catch (e) {
            console.error('Failed', e);
            return Promise.reject({ status: 1, message: e });
        }
    }
    public checkWalletConnected = async () => {
        try {
            if (!this.ethereum) { return alert('Please install meta mask '); };
            const accounts = await this.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
            const { chainId } = await provider.getNetwork();
            if (chainId !== 1) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Please connect to Ethereum Mainnet',
                    heightAuto: false,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true
                });
            }
            else if (accounts[0] && chainId === 1) {
                this.address = accounts[0];
                await this.checkWhiteList();
                await this.getPrice();
            }
            return accounts;
        }
        catch (e) {
            throw new Error('No ethereum object found');
        }
    };


    async mintNFT(valueToSend = 0, amountOfNFTs = 0) {
        const contractAddress = this.chainId === 43113 ? WackyWassiesNFT.testNetAddress : WackyWassiesNFT.address;
        // console.log('contractAddress', contractAddress);
        // console.log('valueToSend', valueToSend);
        // console.log('valueToSend', { value: valueToSend.toString(10) });
        // console.log('valueToSend', { value: (new BigNumber(valueToSend).multipliedBy(Math.pow(10, 18))).toString(10) });
        // console.log('amountOfNFTs', amountOfNFTs);
        const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
        const signer = provider.getSigner();
        const cont = new ethers.Contract(contractAddress, WackyWassiesNFT.abi, signer);
        try {
            const options: any = { value: (new BigNumber(valueToSend).multipliedBy(Math.pow(10, 18))).toString(10) };
            // console.log('options', options);
            let txHash = null;
            if (this.isPreSale) {
                txHash = await cont.preSaleMint(amountOfNFTs, options).then((re) => re);
            }
            else if (this.isPublicSale) {
                txHash = await cont.publicSaleMint(amountOfNFTs, options).then((re) => re);
            }
            // console.log(txHash);
            this.addToTransactinoQueue(txHash);
            return txHash;
        } catch (e) {
            console.error('Failed', e);
            console.error(e);
            Swal.fire({
                position: 'bottom',
                icon: 'error',
                heightAuto: true,
                title: e.reason,
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
            return Promise.reject({ status: 1, message: e });
        }
    }

    async claimNFT() {
        const contractAddress = this.chainId === 43113 ? WackyWassiesNFT.testNetAddress : WackyWassiesNFT.address;
        const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
        const signer = provider.getSigner();
        const cont = new ethers.Contract(contractAddress, WackyWassiesNFT.abi, signer);
        try {
            const txHash = await cont.claim().then((re) => re);
            // console.log(txHash);
            this.addToTransactinoQueue(txHash);
            await this.getPrice();
            return txHash;
        } catch (e) {
            console.error('Failed', e);
            console.error(e);
            Swal.fire({
                position: 'bottom',
                icon: 'error',
                heightAuto: true,
                title: e.reason,
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
            return Promise.reject({ status: 1, message: e });
        }
    }

    async addToTransactinoQueue(tx) {
        // console.log('starting', tx);
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            heightAuto: false,
            title: 'Transaction Pending',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
        const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
        const reciept = await provider.waitForTransaction(tx.hash);
        this.transactionMessage(reciept);
        this.checkWhiteList();
        this.getPrice();
    }

    async transactionMessage(x) {
        let message = 'Transaction Failed';
        let icon: any = 'error';
        if (x.status === 1) {
            message = 'Transaction Successfull';
            icon = 'success';
        }
        Swal.fire({
            position: 'top-end',
            icon,
            title: message,
            heightAuto: false,
            showConfirmButton: false,
            timer: 2000,
            toast: true
        });
    }

    async joinWhitelist() {
        try {
            const contractAddress = this.chainId === 43113 ? Whitelist.testNetAddress : Whitelist.address;
            // console.log(contractAddress);
            const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
            const signer = provider.getSigner();
            const cont = new ethers.Contract(contractAddress, Whitelist.abi, signer);
            // console.log(cont);
            const txHash = await cont['addToWhitelist()']().then((re) => re);
            // console.log(txHash);
            this.addToTransactinoQueue(txHash);
            return txHash;
        } catch (e) {
            console.error('Failed', e);
            return Promise.reject({ status: 1, message: e });
        }
    }

    async getPrice() {

        this.loading = true;
        const contractAddress = this.chainId === 43113 ? WackyWassiesNFT.testNetAddress : WackyWassiesNFT.address;
        // console.log(contractAddress);
        // const contractAddress = WackyWassiesNFT.address;
        const provider = new ethers.providers.Web3Provider((window as any).ethereum, 'any');
        const signer = provider.getSigner();
        const cont = await new ethers.Contract(contractAddress, WackyWassiesNFT.abi, signer);
        try {
            // console.log('here');
            // const contract = new ethers.Contract(contractAddress, WackyWassiesNFT.abi, provider);
            this.isPreSale = false;
            this.isPublicSale = false;
            this.isPreSale = await cont.preSaleActive();
            // console.log(this.isPreSale);
            this.isPublicSale = await cont.publicSaleActive();
            // console.log(this.isPublicSale);

            // const myWass = await cont.balanceOf(this.address);
            const tempWassies = ethers.utils.formatEther(await cont.balanceOf(this.address));
            this.myWassies = (new BigNumber(tempWassies).multipliedBy(Math.pow(10, 18))).toString(10);
            // console.log('myWassies', this.myWassies);
            const publicPrice = (await cont.PUBLIC_SALE_PRICE());
            const prePrice = await cont.PRE_SALE_PRICE();
            const newPublicPrice = ethers.utils.formatEther(publicPrice);
            const newPreSalePrice = ethers.utils.formatEther(prePrice);
            // console.log('newPreSalePrice', newPreSalePrice);
            // console.log('newPublicPrice', newPublicPrice);

            const isClaimable = await cont.isClaimable(this.address);
            console.log('isClaimable', isClaimable);
            this.isClaimable = isClaimable;
            if (this.isPreSale) {
                console.log('Presale');
                this.price = newPreSalePrice;
            }
            else if (this.isPublicSale) {
                console.log('PublicSale');
                this.price = newPublicPrice;
            }
            console.log('price', this.price);
            this.isOpen = this.isClaimable || this.isPublicSale || this.isPreSale;
            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.price = null;
            this.isOpen = false;
            console.error('Failed', e);
            console.error(e);
            Swal.fire({
                position: 'bottom',
                icon: 'error',
                heightAuto: true,
                title: e.reason,
                showConfirmButton: false,
                timer: 1500,
                toast: true
            });
            return Promise.reject({ status: 1, message: e });
        }
    }
}
