import { BlockchainService } from './../services/blockchain.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mint',
  templateUrl: './mint.page.html',
  styleUrls: ['./mint.page.scss'],
})
export class MintPage implements OnInit {
  amountToMint = 1;
  constructor(public bs: BlockchainService) { }

  async ngOnInit() {
    await this.bs.getPrice();
  }

  changeAmount(amount) {
    this.amountToMint = Math.floor(amount);
  }

  async mintNFT() {
    const price = this.bs.price * this.amountToMint;
    await this.bs.mintNFT(price, this.amountToMint);
  }

}
